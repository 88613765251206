@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-grey-3 font-primary text-base leading-relaxed;
  }
  .section {
    @apply py-[50px] lg:py-[70px];
  }
  .title {
    @apply text-2xl lg:text-[40px] leading-tight font-semibold mb-4;
  }
  .subtitle {
    @apply text-[18px] lg:text-[20px] leading-relaxed mb-5 lg:mb-9;
  }
}

/*  */

.header_shape {
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
}

.header_shape::before {
  content: "";
  background-color: #7ed956;
  opacity: 65%;
  transform: skewX(-15deg);
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.header_image {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 52px;
  padding-left: 20px;
  padding-right: 20px;
  top: 0px;
  right: 0px;
  width: 100%;
}

@media (min-width: 960px) {
  .header_image {
    height: 100%;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    width: 50%;
  }
}

.header_image img {
  max-width: 100%;
}

@media (min-width: 960px) {
  .header_image img {
    width: auto;
  }
}

@media (min-width: 1140px) {
  .header_image img {
    max-width: 720px;
  }
}

/* ========== about ========== */

.about_image {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 52px;
  padding-left: 20px;
  padding-right: 20px;
  top: 0px;
  left: 0px;
  width: 100%;
}

@media (min-width: 960px) {
  .about_image {
    height: 100%;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    position: absolute;
    width: 50%;
  }
}

.about_image img {
  max-width: 100%;
}

@media (min-width: 960px) {
  .about_image img {
    max-width: 720px;
    width: auto;
  }
}

.about_check i {
  --tw-bg-opacity: 1;
  background-color: #7ed956;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 4px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 28px;
}

/* services
 */
.single_services {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
}

.single_services:hover {
  --tw-border-opacity: 1;
  border-color: #7ed956;
}

.single_services {
  border-radius: 0.75rem;
  border-style: dashed;
  border-width: 2px;
  /* padding-left: 24px;
  padding-right: 24px;
  padding-top: 52px;
  padding-bottom: 52px; */
}

.single_services:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_services {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

@media (min-width: 540px) {
  .single_services {
    /* padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px; */
  }
}

@media (min-width: 720px) {
  .single_services {
    /* padding-left: 24px;
    padding-right: 24px;
    padding-top: 52px;
    padding-bottom: 52px; */
  }
}

.services_icon {
  display: inline-block;
  position: relative;
}

.services_icon i {
  font-size: 1rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  --tw-text-opacity: 1;
  color: rgba(56, 66, 77, var(--tw-text-opacity));
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.services_shape {
  fill: #cde8c2;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.single_services:hover .services_icon svg {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.single_services:hover .services_shape {
  fill: #7ed956;
  --tw-text-opacity: 1;
  color: rgba(249, 79, 79, var(--tw-text-opacity));
}

/* ======== team ======= */

.tns-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  bottom: -20px;
  width: 100%;
  z-index: 10;
}

.tns-nav button {
  --tw-bg-opacity: 1;
  background-color: #7ed956;
  --tw-bg-opacity: 0.3;
}

.tns-nav button:hover {
  --tw-bg-opacity: 1;
}

.tns-nav button {
  border-radius: 9999px;
  border-style: none;
  height: 12px;
  margin-left: 8px;
  margin-right: 8px;
  width: 12px;
}

.tns-nav button.tns-nav-active {
  --tw-bg-opacity: 1;
}

.single_team_item {
  max-width: 405px;
}

.single_team {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-transparent {
  background-color: transparent;
}

.single_team:hover .group-hover\:bg-current {
  background-color: currentColor;
}

.single_team:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-theme-color {
  --tw-bg-opacity: 1;
  background-color: #7ed956;
}

.single_team:hover .group-hover\:bg-border-color {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-body-color {
  --tw-bg-opacity: 1;
  background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-heading-color {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
}

.single_team:hover .group-hover\:bg-shadow {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
}

.single_team:hover {
  --tw-bg-opacity: 1;
  background-color: #7ed956;
}

.single_team:hover .group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.single_team:hover .group-hover\:bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.single_team:hover .group-hover\:bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.single_team:hover .group-hover\:bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.single_team:hover .group-hover\:bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.single_team:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.single_team:hover .group-hover\:bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.single_team:hover .group-hover\:bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.single_team:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.single_team:hover .group-hover\:bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.single_team:hover .group-hover\:bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.single_team:hover .group-hover\:bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.single_team:hover .group-hover\:bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}

.single_team:hover .group-hover\:bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}

.single_team:hover .group-hover\:bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.single_team:hover .group-hover\:border-transparent {
  border-color: transparent;
}

.single_team:hover .group-hover\:border-current {
  border-color: currentColor;
}

.single_team:hover .group-hover\:border-black {
  --tw-border-opacity: 1;
  border-color: rgba(56, 66, 77, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-gray {
  --tw-border-opacity: 1;
  border-color: rgba(251, 251, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-yellow {
  --tw-border-opacity: 1;
  border-color: rgba(253, 212, 70, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-theme-color {
  --tw-border-opacity: 1;
  border-color: rgba(249, 79, 79, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-border-color {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-body-color {
  --tw-border-opacity: 1;
  border-color: rgba(116, 126, 136, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-heading-color {
  --tw-border-opacity: 1;
  border-color: rgba(22, 36, 71, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-shadow {
  --tw-border-opacity: 1;
  border-color: rgba(157, 106, 106, var(--tw-border-opacity));
}

.single_team:hover .group-hover\:border-opacity-0 {
  --tw-border-opacity: 0;
}

.single_team:hover .group-hover\:border-opacity-5 {
  --tw-border-opacity: 0.05;
}

.single_team:hover .group-hover\:border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.single_team:hover .group-hover\:border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.single_team:hover .group-hover\:border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.single_team:hover .group-hover\:border-opacity-30 {
  --tw-border-opacity: 0.3;
}

.single_team:hover .group-hover\:border-opacity-40 {
  --tw-border-opacity: 0.4;
}

.single_team:hover .group-hover\:border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.single_team:hover .group-hover\:border-opacity-60 {
  --tw-border-opacity: 0.6;
}

.single_team:hover .group-hover\:border-opacity-70 {
  --tw-border-opacity: 0.7;
}

.single_team:hover .group-hover\:border-opacity-75 {
  --tw-border-opacity: 0.75;
}

.single_team:hover .group-hover\:border-opacity-80 {
  --tw-border-opacity: 0.8;
}

.single_team:hover .group-hover\:border-opacity-90 {
  --tw-border-opacity: 0.9;
}

.single_team:hover .group-hover\:border-opacity-95 {
  --tw-border-opacity: 0.95;
}

.single_team:hover .group-hover\:border-opacity-100 {
  --tw-border-opacity: 1;
}

.single_team {
  border-radius: 0.75rem;
  margin-top: 32px;
  margin-bottom: 32px;
}

.single_team:hover .group-hover\:opacity-0 {
  opacity: 0;
}

.single_team:hover .group-hover\:opacity-5 {
  opacity: 0.05;
}

.single_team:hover .group-hover\:opacity-10 {
  opacity: 0.1;
}

.single_team:hover .group-hover\:opacity-20 {
  opacity: 0.2;
}

.single_team:hover .group-hover\:opacity-25 {
  opacity: 0.25;
}

.single_team:hover .group-hover\:opacity-30 {
  opacity: 0.3;
}

.single_team:hover .group-hover\:opacity-40 {
  opacity: 0.4;
}

.single_team:hover .group-hover\:opacity-50 {
  opacity: 0.5;
}

.single_team:hover .group-hover\:opacity-60 {
  opacity: 0.6;
}

.single_team:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}

.single_team:hover .group-hover\:opacity-75 {
  opacity: 0.75;
}

.single_team:hover .group-hover\:opacity-80 {
  opacity: 0.8;
}

.single_team:hover .group-hover\:opacity-90 {
  opacity: 0.9;
}

.single_team:hover .group-hover\:opacity-95 {
  opacity: 0.95;
}

.single_team:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.single_team {
  overflow: hidden;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.single_team:hover .group-hover\:text-transparent {
  color: transparent;
}

.single_team:hover .group-hover\:text-current {
  color: currentColor;
}

.single_team:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgba(56, 66, 77, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-gray {
  --tw-text-opacity: 1;
  color: rgba(251, 251, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-yellow {
  --tw-text-opacity: 1;
  color: rgba(253, 212, 70, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-theme-color {
  --tw-text-opacity: 1;
  color: rgba(249, 79, 79, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-border-color {
  --tw-text-opacity: 1;
  color: rgba(232, 232, 232, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-body-color {
  --tw-text-opacity: 1;
  color: rgba(116, 126, 136, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-heading-color {
  --tw-text-opacity: 1;
  color: rgba(22, 36, 71, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-shadow {
  --tw-text-opacity: 1;
  color: rgba(157, 106, 106, var(--tw-text-opacity));
}

.single_team:hover .group-hover\:text-opacity-0 {
  --tw-text-opacity: 0;
}

.single_team:hover .group-hover\:text-opacity-5 {
  --tw-text-opacity: 0.05;
}

.single_team:hover .group-hover\:text-opacity-10 {
  --tw-text-opacity: 0.1;
}

.single_team:hover .group-hover\:text-opacity-20 {
  --tw-text-opacity: 0.2;
}

.single_team:hover .group-hover\:text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.single_team:hover .group-hover\:text-opacity-30 {
  --tw-text-opacity: 0.3;
}

.single_team:hover .group-hover\:text-opacity-40 {
  --tw-text-opacity: 0.4;
}

.single_team:hover .group-hover\:text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.single_team:hover .group-hover\:text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.single_team:hover .group-hover\:text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.single_team:hover .group-hover\:text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.single_team:hover .group-hover\:text-opacity-80 {
  --tw-text-opacity: 0.8;
}

.single_team:hover .group-hover\:text-opacity-90 {
  --tw-text-opacity: 0.9;
}

.single_team:hover .group-hover\:text-opacity-95 {
  --tw-text-opacity: 0.95;
}

.single_team:hover .group-hover\:text-opacity-100 {
  --tw-text-opacity: 1;
}

.single_team:hover .group-hover\:underline {
  text-decoration: underline;
}

.single_team:hover .group-hover\:line-through {
  text-decoration: line-through;
}

.single_team:hover .group-hover\:no-underline {
  text-decoration: none;
}

.single_team {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

@media (min-width: 540px) {
  .single_team:hover .sm\:group-hover\:bg-transparent {
    background-color: transparent;
  }

  .single_team:hover .sm\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .single_team:hover .sm\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-theme-color {
    --tw-bg-opacity: 1;
    background-color: #7ed956;
  }

  .single_team:hover .sm\:group-hover\:bg-border-color {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-body-color {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-heading-color {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-shadow {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .single_team:hover .sm\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .single_team:hover .sm\:group-hover\:border-transparent {
    border-color: transparent;
  }

  .single_team:hover .sm\:group-hover\:border-current {
    border-color: currentColor;
  }

  .single_team:hover .sm\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(56, 66, 77, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(251, 251, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(253, 212, 70, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-theme-color {
    --tw-border-opacity: 1;
    border-color: rgba(249, 79, 79, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-border-color {
    --tw-border-opacity: 1;
    border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-body-color {
    --tw-border-opacity: 1;
    border-color: rgba(116, 126, 136, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-heading-color {
    --tw-border-opacity: 1;
    border-color: rgba(22, 36, 71, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-shadow {
    --tw-border-opacity: 1;
    border-color: rgba(157, 106, 106, var(--tw-border-opacity));
  }

  .single_team:hover .sm\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .single_team:hover .sm\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .single_team:hover .sm\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .single_team:hover .sm\:group-hover\:opacity-5 {
    opacity: 0.05;
  }

  .single_team:hover .sm\:group-hover\:opacity-10 {
    opacity: 0.1;
  }

  .single_team:hover .sm\:group-hover\:opacity-20 {
    opacity: 0.2;
  }

  .single_team:hover .sm\:group-hover\:opacity-25 {
    opacity: 0.25;
  }

  .single_team:hover .sm\:group-hover\:opacity-30 {
    opacity: 0.3;
  }

  .single_team:hover .sm\:group-hover\:opacity-40 {
    opacity: 0.4;
  }

  .single_team:hover .sm\:group-hover\:opacity-50 {
    opacity: 0.5;
  }

  .single_team:hover .sm\:group-hover\:opacity-60 {
    opacity: 0.6;
  }

  .single_team:hover .sm\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  .single_team:hover .sm\:group-hover\:opacity-75 {
    opacity: 0.75;
  }

  .single_team:hover .sm\:group-hover\:opacity-80 {
    opacity: 0.8;
  }

  .single_team:hover .sm\:group-hover\:opacity-90 {
    opacity: 0.9;
  }

  .single_team:hover .sm\:group-hover\:opacity-95 {
    opacity: 0.95;
  }

  .single_team:hover .sm\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .single_team:hover .sm\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .sm\:group-hover\:text-transparent {
    color: transparent;
  }

  .single_team:hover .sm\:group-hover\:text-current {
    color: currentColor;
  }

  .single_team:hover .sm\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(56, 66, 77, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(251, 251, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(253, 212, 70, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-theme-color {
    --tw-text-opacity: 1;
    color: rgba(249, 79, 79, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-border-color {
    --tw-text-opacity: 1;
    color: rgba(232, 232, 232, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-body-color {
    --tw-text-opacity: 1;
    color: rgba(116, 126, 136, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-heading-color {
    --tw-text-opacity: 1;
    color: rgba(22, 36, 71, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-shadow {
    --tw-text-opacity: 1;
    color: rgba(157, 106, 106, var(--tw-text-opacity));
  }

  .single_team:hover .sm\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .single_team:hover .sm\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .single_team:hover .sm\:group-hover\:underline {
    text-decoration: underline;
  }

  .single_team:hover .sm\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .single_team:hover .sm\:group-hover\:no-underline {
    text-decoration: none;
  }
}

@media (min-width: 720px) {
  .single_team:hover .md\:group-hover\:bg-transparent {
    background-color: transparent;
  }

  .single_team:hover .md\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .single_team:hover .md\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-theme-color {
    --tw-bg-opacity: 1;
    background-color: #7ed956;
  }

  .single_team:hover .md\:group-hover\:bg-border-color {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-body-color {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-heading-color {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-shadow {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
  }

  .single_team:hover .md\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .single_team:hover .md\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .single_team:hover .md\:group-hover\:border-transparent {
    border-color: transparent;
  }

  .single_team:hover .md\:group-hover\:border-current {
    border-color: currentColor;
  }

  .single_team:hover .md\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(56, 66, 77, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(251, 251, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(253, 212, 70, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-theme-color {
    --tw-border-opacity: 1;
    border-color: rgba(249, 79, 79, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-border-color {
    --tw-border-opacity: 1;
    border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-body-color {
    --tw-border-opacity: 1;
    border-color: rgba(116, 126, 136, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-heading-color {
    --tw-border-opacity: 1;
    border-color: rgba(22, 36, 71, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-shadow {
    --tw-border-opacity: 1;
    border-color: rgba(157, 106, 106, var(--tw-border-opacity));
  }

  .single_team:hover .md\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .single_team:hover .md\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .single_team:hover .md\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .single_team:hover .md\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .single_team:hover .md\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .single_team:hover .md\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .single_team:hover .md\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .single_team:hover .md\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .single_team:hover .md\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .single_team:hover .md\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .single_team:hover .md\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .single_team:hover .md\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .single_team:hover .md\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .single_team:hover .md\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .single_team:hover .md\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .single_team:hover .md\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .single_team:hover .md\:group-hover\:opacity-5 {
    opacity: 0.05;
  }

  .single_team:hover .md\:group-hover\:opacity-10 {
    opacity: 0.1;
  }

  .single_team:hover .md\:group-hover\:opacity-20 {
    opacity: 0.2;
  }

  .single_team:hover .md\:group-hover\:opacity-25 {
    opacity: 0.25;
  }

  .single_team:hover .md\:group-hover\:opacity-30 {
    opacity: 0.3;
  }

  .single_team:hover .md\:group-hover\:opacity-40 {
    opacity: 0.4;
  }

  .single_team:hover .md\:group-hover\:opacity-50 {
    opacity: 0.5;
  }

  .single_team:hover .md\:group-hover\:opacity-60 {
    opacity: 0.6;
  }

  .single_team:hover .md\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  .single_team:hover .md\:group-hover\:opacity-75 {
    opacity: 0.75;
  }

  .single_team:hover .md\:group-hover\:opacity-80 {
    opacity: 0.8;
  }

  .single_team:hover .md\:group-hover\:opacity-90 {
    opacity: 0.9;
  }

  .single_team:hover .md\:group-hover\:opacity-95 {
    opacity: 0.95;
  }

  .single_team:hover .md\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .single_team:hover .md\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .md\:group-hover\:text-transparent {
    color: transparent;
  }

  .single_team:hover .md\:group-hover\:text-current {
    color: currentColor;
  }

  .single_team:hover .md\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(56, 66, 77, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(251, 251, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(253, 212, 70, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-theme-color {
    --tw-text-opacity: 1;
    color: rgba(249, 79, 79, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-border-color {
    --tw-text-opacity: 1;
    color: rgba(232, 232, 232, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-body-color {
    --tw-text-opacity: 1;
    color: rgba(116, 126, 136, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-heading-color {
    --tw-text-opacity: 1;
    color: rgba(22, 36, 71, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-shadow {
    --tw-text-opacity: 1;
    color: rgba(157, 106, 106, var(--tw-text-opacity));
  }

  .single_team:hover .md\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .single_team:hover .md\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .single_team:hover .md\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .single_team:hover .md\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .single_team:hover .md\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .single_team:hover .md\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .single_team:hover .md\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .single_team:hover .md\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .single_team:hover .md\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .single_team:hover .md\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .single_team:hover .md\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .single_team:hover .md\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .single_team:hover .md\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .single_team:hover .md\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .single_team:hover .md\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .single_team:hover .md\:group-hover\:underline {
    text-decoration: underline;
  }

  .single_team:hover .md\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .single_team:hover .md\:group-hover\:no-underline {
    text-decoration: none;
  }
}

@media (min-width: 960px) {
  .single_team:hover .lg\:group-hover\:bg-transparent {
    background-color: transparent;
  }

  .single_team:hover .lg\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .single_team:hover .lg\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-theme-color {
    --tw-bg-opacity: 1;
    background-color: #7ed956;
  }

  .single_team:hover .lg\:group-hover\:bg-border-color {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-body-color {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-heading-color {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-shadow {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .single_team:hover .lg\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .single_team:hover .lg\:group-hover\:border-transparent {
    border-color: transparent;
  }

  .single_team:hover .lg\:group-hover\:border-current {
    border-color: currentColor;
  }

  .single_team:hover .lg\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(56, 66, 77, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(251, 251, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(253, 212, 70, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-theme-color {
    --tw-border-opacity: 1;
    border-color: rgba(249, 79, 79, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-border-color {
    --tw-border-opacity: 1;
    border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-body-color {
    --tw-border-opacity: 1;
    border-color: rgba(116, 126, 136, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-heading-color {
    --tw-border-opacity: 1;
    border-color: rgba(22, 36, 71, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-shadow {
    --tw-border-opacity: 1;
    border-color: rgba(157, 106, 106, var(--tw-border-opacity));
  }

  .single_team:hover .lg\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .single_team:hover .lg\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .single_team:hover .lg\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .single_team:hover .lg\:group-hover\:opacity-5 {
    opacity: 0.05;
  }

  .single_team:hover .lg\:group-hover\:opacity-10 {
    opacity: 0.1;
  }

  .single_team:hover .lg\:group-hover\:opacity-20 {
    opacity: 0.2;
  }

  .single_team:hover .lg\:group-hover\:opacity-25 {
    opacity: 0.25;
  }

  .single_team:hover .lg\:group-hover\:opacity-30 {
    opacity: 0.3;
  }

  .single_team:hover .lg\:group-hover\:opacity-40 {
    opacity: 0.4;
  }

  .single_team:hover .lg\:group-hover\:opacity-50 {
    opacity: 0.5;
  }

  .single_team:hover .lg\:group-hover\:opacity-60 {
    opacity: 0.6;
  }

  .single_team:hover .lg\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  .single_team:hover .lg\:group-hover\:opacity-75 {
    opacity: 0.75;
  }

  .single_team:hover .lg\:group-hover\:opacity-80 {
    opacity: 0.8;
  }

  .single_team:hover .lg\:group-hover\:opacity-90 {
    opacity: 0.9;
  }

  .single_team:hover .lg\:group-hover\:opacity-95 {
    opacity: 0.95;
  }

  .single_team:hover .lg\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .single_team:hover .lg\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .lg\:group-hover\:text-transparent {
    color: transparent;
  }

  .single_team:hover .lg\:group-hover\:text-current {
    color: currentColor;
  }

  .single_team:hover .lg\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(56, 66, 77, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(251, 251, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(253, 212, 70, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-theme-color {
    --tw-text-opacity: 1;
    color: rgba(249, 79, 79, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-border-color {
    --tw-text-opacity: 1;
    color: rgba(232, 232, 232, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-body-color {
    --tw-text-opacity: 1;
    color: rgba(116, 126, 136, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-heading-color {
    --tw-text-opacity: 1;
    color: rgba(22, 36, 71, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-shadow {
    --tw-text-opacity: 1;
    color: rgba(157, 106, 106, var(--tw-text-opacity));
  }

  .single_team:hover .lg\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .single_team:hover .lg\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .single_team:hover .lg\:group-hover\:underline {
    text-decoration: underline;
  }

  .single_team:hover .lg\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .single_team:hover .lg\:group-hover\:no-underline {
    text-decoration: none;
  }
}

@media (min-width: 1140px) {
  .single_team:hover .xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }

  .single_team:hover .xl\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .single_team:hover .xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-theme-color {
    --tw-bg-opacity: 1;
    background-color: #7ed956;
  }

  .single_team:hover .xl\:group-hover\:bg-border-color {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-body-color {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-heading-color {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-shadow {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .single_team:hover .xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .single_team:hover .xl\:group-hover\:border-transparent {
    border-color: transparent;
  }

  .single_team:hover .xl\:group-hover\:border-current {
    border-color: currentColor;
  }

  .single_team:hover .xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(56, 66, 77, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(251, 251, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(253, 212, 70, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-theme-color {
    --tw-border-opacity: 1;
    border-color: rgba(249, 79, 79, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-border-color {
    --tw-border-opacity: 1;
    border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-body-color {
    --tw-border-opacity: 1;
    border-color: rgba(116, 126, 136, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-heading-color {
    --tw-border-opacity: 1;
    border-color: rgba(22, 36, 71, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-shadow {
    --tw-border-opacity: 1;
    border-color: rgba(157, 106, 106, var(--tw-border-opacity));
  }

  .single_team:hover .xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .single_team:hover .xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .single_team:hover .xl\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .single_team:hover .xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }

  .single_team:hover .xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }

  .single_team:hover .xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }

  .single_team:hover .xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }

  .single_team:hover .xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }

  .single_team:hover .xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }

  .single_team:hover .xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }

  .single_team:hover .xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }

  .single_team:hover .xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  .single_team:hover .xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }

  .single_team:hover .xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }

  .single_team:hover .xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }

  .single_team:hover .xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }

  .single_team:hover .xl\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .single_team:hover .xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .xl\:group-hover\:text-transparent {
    color: transparent;
  }

  .single_team:hover .xl\:group-hover\:text-current {
    color: currentColor;
  }

  .single_team:hover .xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(56, 66, 77, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(251, 251, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(253, 212, 70, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-theme-color {
    --tw-text-opacity: 1;
    color: rgba(249, 79, 79, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-border-color {
    --tw-text-opacity: 1;
    color: rgba(232, 232, 232, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-body-color {
    --tw-text-opacity: 1;
    color: rgba(116, 126, 136, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-heading-color {
    --tw-text-opacity: 1;
    color: rgba(22, 36, 71, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-shadow {
    --tw-text-opacity: 1;
    color: rgba(157, 106, 106, var(--tw-text-opacity));
  }

  .single_team:hover .xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .single_team:hover .xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .single_team:hover .xl\:group-hover\:underline {
    text-decoration: underline;
  }

  .single_team:hover .xl\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .single_team:hover .xl\:group-hover\:no-underline {
    text-decoration: none;
  }
}

@media (min-width: 1320px) {
  .single_team:hover .\32xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }

  .single_team:hover .\32xl\:group-hover\:bg-current {
    background-color: currentColor;
  }

  .single_team:hover .\32xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(56, 66, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 251, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 212, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-theme-color {
    --tw-bg-opacity: 1;
    background-color: #7ed956;
  }

  .single_team:hover .\32xl\:group-hover\:bg-border-color {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 232, 232, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-body-color {
    --tw-bg-opacity: 1;
    background-color: rgba(116, 126, 136, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-heading-color {
    --tw-bg-opacity: 1;
    background-color: rgba(22, 36, 71, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-shadow {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 106, 106, var(--tw-bg-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }

  .single_team:hover .\32xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .single_team:hover .\32xl\:group-hover\:border-transparent {
    border-color: transparent;
  }

  .single_team:hover .\32xl\:group-hover\:border-current {
    border-color: currentColor;
  }

  .single_team:hover .\32xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(56, 66, 77, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(251, 251, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(253, 212, 70, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-theme-color {
    --tw-border-opacity: 1;
    border-color: rgba(249, 79, 79, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-border-color {
    --tw-border-opacity: 1;
    border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-body-color {
    --tw-border-opacity: 1;
    border-color: rgba(116, 126, 136, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-heading-color {
    --tw-border-opacity: 1;
    border-color: rgba(22, 36, 71, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-shadow {
    --tw-border-opacity: 1;
    border-color: rgba(157, 106, 106, var(--tw-border-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }

  .single_team:hover .\32xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-0 {
    opacity: 0;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }

  .single_team:hover .\32xl\:group-hover\:opacity-100 {
    opacity: 1;
  }

  .single_team:hover .\32xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .single_team:hover .\32xl\:group-hover\:text-transparent {
    color: transparent;
  }

  .single_team:hover .\32xl\:group-hover\:text-current {
    color: currentColor;
  }

  .single_team:hover .\32xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(56, 66, 77, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(251, 251, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(253, 212, 70, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-theme-color {
    --tw-text-opacity: 1;
    color: rgba(249, 79, 79, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-border-color {
    --tw-text-opacity: 1;
    color: rgba(232, 232, 232, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-body-color {
    --tw-text-opacity: 1;
    color: rgba(116, 126, 136, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-heading-color {
    --tw-text-opacity: 1;
    color: rgba(22, 36, 71, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-shadow {
    --tw-text-opacity: 1;
    color: rgba(157, 106, 106, var(--tw-text-opacity));
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }

  .single_team:hover .\32xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .single_team:hover .\32xl\:group-hover\:underline {
    text-decoration: underline;
  }

  .single_team:hover .\32xl\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .single_team:hover .\32xl\:group-hover\:no-underline {
    text-decoration: none;
  }
}

.team_image .social li {
  margin-top: 16px;
  opacity: 0;
  visibility: hidden;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 100%;
}

.team_image .social li a {
  background-color: #cde8c2;
}

.team_image .social li a:hover {
  --tw-bg-opacity: 1;
  background-color: #7ed956;
}

.team_image .social li a {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.team_image .social li a:hover {
  --tw-border-opacity: 1;
  border-color: #7ed956;
}

.team_image .social li a {
  border-radius: 9999px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  width: 36px;
}

.single_team:hover .social li {
  opacity: 1;
  visibility: visible;
  --tw-translate-x: 0px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.single_team:hover .social li:nth-child(1) {
  transition-delay: 100ms;
}

.single_team:hover .social li:nth-child(2) {
  transition-delay: 200ms;
}

.single_team:hover .social li:nth-child(3) {
  transition-delay: 300ms;
}

.single_team:hover .social li:nth-child(4) {
  transition-delay: 400ms;
}

/*  */
.bg-gradient-cover {
  background-color: transparent;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.92));
  background-position-y: -1px;
}

/* .max-h-98 {
   max-height: 24.3rem;
 }

 .box-one>article:nth-child(1) {
   padding-top: 0;
   padding-bottom: 0.125rem;
   padding-right: 0.125rem;
 }

 .box-one>article:nth-child(2) {
   padding-top: 0;
   padding-bottom: 0.125rem;
   padding-left: 0.125rem;
 }

 .box-one>article:nth-child(3) {
   padding-top: 0.125rem;
   padding-bottom: 0.125rem;
   padding-right: 0.125rem;
 }

 .box-one>article:nth-child(4) {
   padding-top: 0.125rem;
   padding-bottom: 0.125rem;
   padding-left: 0.125rem;
 } */

/*  */

/* .linearGradient {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 7) 40%,
    rgba(0, 0, 0, 0) 100%
  );
} */

/*  */

.card-zoom {
  @apply relative flex items-center justify-center  overflow-hidden shadow-xl h-80   rounded-2xl;
}
.card-zoom-image {
  @apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute font-semibold text-center text-5xl  transition-all duration-500 ease-in-out transform scale-150 text-gray-50 opacity-60;
}
/* .card-zoom:hover .card-zoom-image {
  @apply scale-150;
} */

/* .card-zoom:hover .card-zoom-text {
  @apply scale-100;
} */

/*  */

.services_list {
  @apply mb-44 lg:mb-24 cursor-pointer;
}
/* .service_list_image {
  @apply transition-all duration-500 ease-in-out transform;
} */

/* .services_list:hover .service_list_image {
  @apply scale-75;
} */
/* .service_list_image:hover {
  @apply ;
} */
.small_card_service {
  @apply bg-white text-black  text-center rounded-sm border-b-4 border-primary opacity-90 w-5/6 inset-x-[8.33%] top-2/3  absolute;
}
.services_list:hover .small_card_service {
  @apply top-[63%] transition-all duration-500 ease-in-out transform;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  /* width: 13.5rem;
  height: 13.5rem; */
  margin: 110px auto 0;

  /* border: solid 2px #8822aa; */
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.work_process {
  @apply cursor-pointer;
}
.work_process:hover .service_load_step {
  @apply bg-primary transition-all duration-500 ease-in-out;
}
.work_process:hover .load {
  @apply block;
}
.service_load_step {
  @apply absolute duration-500 flex items-center bg-gray-700 rounded-full w-12 h-12 -bottom-10 left-[38%];
}
